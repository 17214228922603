
import { computed, defineComponent, onBeforeMount } from "vue";

import {
  IonPage,
  IonContent,
  IonTextarea,
  IonCard,
  IonCardContent,
} from "@ionic/vue";

import SetupModuleHeader from "./SetupModuleHeader.vue";
import SetupDoneButton from "./SetupDoneButton.vue";
import SetupTitleInput from "./SetupTitleInput.vue";

import LanguageService from "../../../lib/service/LanguageService";
import CreateService from "../../../lib/service/CreateService";
import { ValidationResult } from "../../../lib/form/Validation";
import { useRouter } from "vue-router";

export default defineComponent({
  components: {
    IonPage,
    IonContent,
    IonTextarea,
    IonCard,
    IonCardContent,
    SetupModuleHeader,
    SetupDoneButton,
    SetupTitleInput,
  },
  setup() {
    const lang = LanguageService.getTranslator("module/description");
    const router = useRouter();

    const validator = computed<ValidationResult>(() => {
      if (CreateService.info.config.description !== undefined) {
        if (CreateService.info.config.description.title.length <= 2) {
          return {
            valid: false,
            message: "titleTooShort",
          };
        }
        if (CreateService.info.config.description.text.length >= 5) {
          CreateService.configState.description = true;
          return { valid: true };
        } else {
          return {
            valid: false,
            message: "tooShort",
          };
        }
      } else {
        return { valid: false };
      }
    });

    onBeforeMount(() => {
      if (CreateService.info.config.description === undefined) {
        CreateService.info.config.description = {
          title: lang.t("title"),
          text: "",
        };
        CreateService.configState.description = false;
      }
      if (!CreateService.checkGuard("setup")) {
        router.push("/create");
      }
    });

    return {
      lang,
      CreateService,
      validator,
    };
  }
});
